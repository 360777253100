import React, { useState, useEffect, useMemo, ChangeEvent } from "react";
import { useIntl } from "react-intl";
import {
  CircularProgress, styled as materialStyled, Typography,
  SvgIcon, ButtonProps,
  FormGroup,
  FormControlLabel,
  Checkbox, Paper,
  Stack,
  Box, Button, Link
} from "@mui/material";
import { red } from "@mui/material/colors";
import miningGif from "../../../../../assets/miner/mining.gif";
import {
  CardLinkPrices,
  CommunityLivenessContent,
  CommunityLivenessWrapper, StartMiningCard
} from "../styles/Community_liveness";
import backgroundConet from "../../../../../assets/Logo_White_TransparentBG.png";
import { ReactComponent as MiningIcon } from '../../../../../assets/Logo_CoNET.svg';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

import { IoIosArrowForward } from "react-icons/io";
import CloseIcon from '@mui/icons-material/Close';
import { useTypedSelector } from '../../../../../store/store';
import { listeningMiningHook, platform, profile } from "../../../../../API/platform";
import { toast } from "react-toastify";
import { useIsMiningRunning } from "./useIsMiningRunning";
import { useRefreshStore } from "../../../../../API/useRefreshStore";


const ItemStyle3 = materialStyled(Paper)(() => ({
  textAlign: "center",
  borderRadius: "1rem",
  padding: "1rem",
  width: "100%",
}));

const ColorButton = materialStyled(Button)<ButtonProps>(({ theme }) => ({
  "&:hover": {},
  fontFamily: ["Inter", '"Inter Placeholder"', "sans-serif"].join(","),
  fontWeight: "900",
  fontSize: "20px",
  padding: "0.5rem 4rem 0.5rem 4rem",
  borderRadius: "2rem",
}));

interface Ratedata {
  ipaddress: number;
  online: number;
  rate: number;
  status: number;
}

interface mining {
  blockNumber: number
  CCNTP_total_balance: string
  Updated_balace: string
  status: 'mining' | 'stoped'
  profile: profile
}

interface IProps {
  CNTP: string;
  setCNTP: (v: string) => void;
  setTodayCNTP: (v: string) => void;
}
export const Community_liveness: React.FC<IProps> = ({
  CNTP,
  setCNTP,
  setTodayCNTP,
}) => {

  const walletStore = useRefreshStore(state => state.wallets)
  const intl = useIntl();

  //under Review
  const [showSameIPError, setShowSameIPError] = useState(false);
  const [showInstanceError, setShowInstanceError] = useState(false);
  const [showTimeOutError, setShowTimeOutError] = useState(false);
  const [regError, setRegError] = useState(false);
  const [isLoadingMiner, setIsLoadingMiner] = useState(false);

  //Conet Functions
  const [conetPlatform, setConetPlatform] = useState<any>('');
  const [workerLoading, setWorkerLoading] = useState(0);

  const [isMiningLoading, setIsMiningLoading] = useState(false);
  const [isDismissing, setIsDismissing] = useState(false);
  const [retryMiningOption, setRetryMiningOption] = useState<boolean>(false);
  const [hasFailed, setHasFailed] = useState(false);
  const [reconecting, setReconecting] = useState(false);

  const authKey = useTypedSelector(state => state.appState.pass);
  const profileIndex = useTypedSelector(state => state.appState.activeProfile);
  const [profile, setProfile] = useState();
  const [miningStatusHook, setMiningStatusHook] = useState<any>(`{"rate":"0","online":"Calculating...","status":200,"epoch":"0"}`);
  const lastTimeListened = useIsMiningRunning((state) => state.lastTimeListened);
  const lostConnection = useIsMiningRunning((state) => state.lostConnection);

  const isMiningRunning = useIsMiningRunning((state) => state.isMiningRunning);
  const miningStatus = useIsMiningRunning((state) => state.miningStatus);
  const setMiningStatus = useIsMiningRunning((state) => state.setMiningStatus);

  const conet_platform = useMemo(() => new platform(setConetPlatform, setWorkerLoading), []);

  useEffect(() => {
    listeningMiningHook(setMiningStatusHook);

    if(!isMiningRunning){
      return ()=> {
        listeningMiningHook(setMiningStatusHook)
      }
    }
  }, [isMiningRunning]);

  useEffect(() => {
    if (!useIsMiningRunning.getState().checkConnection && isMiningRunning) {
      useIsMiningRunning.getState().setCheckConnection(setInterval(async () => {
        const _IsMiningRunning = useIsMiningRunning.getState().isMiningRunning;
        const _LastTimeListened = useIsMiningRunning.getState().lastTimeListened;
        const _CheckConnection = useIsMiningRunning.getState().checkConnection;

        if ((!lostConnection && Date.now() - _LastTimeListened > 60000)) {
          if (_IsMiningRunning) useIsMiningRunning.getState().toggleConnection(true);
          useIsMiningRunning.getState().setIsMiningRunning(false);
          clearInterval(_CheckConnection);
          useIsMiningRunning.getState().setCheckConnection(undefined);
        }
      }, 2000));
    }
  }, [lastTimeListened]);

  useEffect(() => {
    if (retryMiningOption && lostConnection) {
      (async () => {
        setIsMiningLoading(true);
        const res = await conet_platform.startMining(authKey, profile);
        if (res === 'success') {
          useIsMiningRunning.getState().toggleConnection(false);
          useIsMiningRunning.getState().updateTimeListened();
          setMiningStatus(miningStatusHook);
          useIsMiningRunning.getState().setIsMiningRunning(true);
          setIsMiningLoading(false);
          toast.success('Mining Started', { position: "bottom-center", autoClose: 1500 });
        } else {
          setIsMiningLoading(true);
          await conet_platform.startMining(authKey, profile);
          setIsMiningLoading(false);
        }
      })();
    }
  }, [retryMiningOption, lostConnection]);

  useEffect(() => {
    if (miningStatusHook !== `{"rate":"0","online":"Calculating...","status":200,"epoch":"0"}`) {
      useIsMiningRunning.getState().toggleConnection(false);
      useIsMiningRunning.getState().setIsMiningRunning(true);
      useIsMiningRunning.getState().updateTimeListened();
      setMiningStatus(miningStatusHook);
    }
  }, [miningStatusHook]);

  useEffect(() => {
    const updateProfile = () => {
      const keyID = profileIndex?.keyID?.toLowerCase() || "0";
      const profiles = walletStore;
      const index = profiles.findIndex(n => n?.keyID?.toLowerCase() === keyID);
      if (index >= 0) {
        setProfile(profiles[index]);
      }
    };
    if (walletStore && walletStore.length > 0) {
      updateProfile();
    }
  }, [walletStore]);

  const clickStart = useMemo(() => async () => {
    if (!isMiningRunning && !isMiningLoading && !reconecting) {
      setIsMiningLoading(true);
      if(!profile){
        toast.error('Please wait for wallet update', { position: "bottom-center", autoClose: 1500 });
        setIsMiningLoading(false);
        return;
      }
      const result = await conet_platform.startMining(authKey, profile);
      if (result === 'success') {
        useIsMiningRunning.getState().toggleConnection(false);
        useIsMiningRunning.getState().updateTimeListened();
        setMiningStatus(miningStatusHook);
        useIsMiningRunning.getState().setIsMiningRunning(true);
        setIsMiningLoading(false);
        toast.success('Mining Started', { position: "bottom-center", autoClose: 1500 });
      } else {
        useIsMiningRunning.getState().setIsMiningRunning(false);
        setIsMiningLoading(false);
        if (retryMiningOption) {
          toast.info('Unable to connect - Retrying connection', { position: "bottom-center", autoClose: 1500 });
          retryConnection();
        } else {
          toast.error(result === 'NOT_INTERNET' ? 'No internet connection' : 'Unable to start Mining', { position: "bottom-center", autoClose: 1500 });
        }
      }
    }
  }, [isMiningRunning, isMiningLoading, reconecting, retryMiningOption, authKey, profile, conet_platform, miningStatusHook]);

  const stopDePin = useMemo(() => async () => {
    if (!isLoadingMiner && isMiningRunning) {
      setIsDismissing(true);
      setIsLoadingMiner(true);
      await conet_platform.stopMining();
      setIsDismissing(false);
      useIsMiningRunning.getState().setIsMiningRunning(false);
      useIsMiningRunning.getState().toggleConnection(false);
      setIsLoadingMiner(false);
    }
  }, [isLoadingMiner, isMiningRunning, conet_platform]);

  const clearMiningStatus = useMemo(() => async () => {
    useIsMiningRunning.getState().toggleConnection(false);
    setMiningStatus(`{"rate":"0","online":"Calculating...","status":200,"epoch":"0"}`);
    useIsMiningRunning.getState().setIsMiningRunning(false);
    setHasFailed(false);
  }, []);

  const handleCheckboxToggle = useMemo(() => (e: ChangeEvent) => {
    setRetryMiningOption((prev) => !prev);
  }, []);

  const haveInstanceError = useMemo<boolean>(
    () => showTimeOutError || showInstanceError || showSameIPError || regError,
    [showInstanceError, showSameIPError, regError, showTimeOutError]
  );

  return (
    <CommunityLivenessWrapper>
      <Stack
        direction={{ base: `column`, sm: "row" }}
        maxWidth="1040px"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        gap='28px'
      >
        <CommunityLivenessContent>
          {regError && (
            <Typography
              variant="body1"
              sx={{ textAlign: "center", color: red[700], padding: "0 0 1rem 0" }}
            >
              {intl.formatMessage({
                id: "platform.miner.community.liveness.sameIPError",
              })}
            </Typography>
          )}
          {showSameIPError && (
            <Typography
              variant="body1"
              sx={{ textAlign: "center", color: red[700], padding: "0 0 1rem 0" }}
            >
              {intl.formatMessage({
                id: "platform.miner.community.liveness.sameIPError",
              })}
            </Typography>
          )}
          {showTimeOutError && (
            <Stack spacing={1}>
              <Typography
                variant="body1"
                sx={{ textAlign: "center", color: red[700] }}
              >
                {intl.formatMessage({
                  id: "platform.miner.community.liveness.Timeout1",
                })}
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "center", color: red[700] }}
              >
                {intl.formatMessage({
                  id: "platform.miner.community.liveness.Timeout2",
                })}
              </Typography>
            </Stack>
          )}
          {showInstanceError && (
            <Typography
              variant="body1"
              sx={{ textAlign: "center", color: red[700], padding: "0 0 1rem 0" }}
            >
              {intl.formatMessage({
                id: "platform.miner.community.liveness.sameMinerError",
              })}
            </Typography>
          )}
          {/* <Stack spacing={5} marginBottom="1.5rem" style={{display:'flex', alignItems:'center'}}>
            <Typography
              color={theme.ui.colors.primary}
              sx={{ fontSize: `14px`, textTransform: `uppercase` }}
            >
              {intl.formatMessage({ id: "platform.miner.register.goLive" })}
            </Typography>
            {minting ? (
              <img src={miner2} style={{ width: "5rem" }} />
            ) : (
              <img src={minerPause} style={{ width: "5rem" }} />
            )}
          </Stack>
          <Typography variant="h5" sx={{ fontWeight: "800" }}>
            {CNTP} { } {'CNTP'}
          </Typography> */}
          <CardLinkPrices>
            <img src={backgroundConet} alt="" style={{ position: "absolute", right: 0, bottom: 0 }} />
            <div style={{ zIndex: 1 }}>
              <Link sx={{ cursor: 'pointer', zIndex: '10' }} href='https://conet.network/guardian/' target="_blank">
                <Stack flexDirection={'row'} alignItems={'center'}>
                  <Typography fontSize={'16px'} fontWeight={500}>Learn More</Typography>
                  <IoIosArrowForward fontSize="14px" />
                </Stack>
              </Link>
              <Stack>
                <Typography color='#fff' textAlign={'left'} fontSize={'24px'} fontWeight={700}>Democratizing the CoNET network</Typography>
                <Typography color='#fff' textAlign={'left'} fontSize={'16px'} fontWeight={400}>Become a Guardian to boost your earnings</Typography>
              </Stack>
              {/* color={theme.ui.colors.text.white} */}
            </div>
          </CardLinkPrices>

        </CommunityLivenessContent>

        <Stack
          flexDirection={'column'}
          width={'100%'}
          alignItems={'flex-start'}
          justifyContent={'center'}
        >
          {/* <FlexContent
            direction="column"
            minW="19.6rem"
            justify="center"
            gap="0.75rem"
          >

          </FlexContent> */}
          <Box style={{ width: '100%', position: 'relative' }}>
            {!isMiningRunning && <FormGroup sx={{ fontSize: "14px", border: '1px solid', borderRadius: '50px', borderColor: retryMiningOption ? '#0d0d0d' : '#676768', width: { xs: 'fit-content' }, position: "absolute", top: ['8px', '12px', '24px'], left: ["36px"], padding: { xs: '0px 4px', md: '0px 12px' } }}>
              <FormControlLabel control={<Checkbox checked={retryMiningOption} onClick={(e) => e.stopPropagation()} onChange={handleCheckboxToggle} icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleOutlineIcon />} />} label="Reconnect Automatically" componentsProps={{ typography: { sx: { fontSize: { xs: '12px', md: '14px' }, color: localStorage.getItem('mui-mode') === 'light' ? "#5F5E60" : "#FFFFFF" } } }} />
            </FormGroup>}
            <StartMiningCard onClick={() => {

              if (!isMiningRunning && !lostConnection && !reconecting) {
                clickStart()
              }

            }} style={{
              border: lostConnection ? "row" : "none",
              background: isMiningRunning ? "#1c1b25" : lostConnection || hasFailed ? localStorage.getItem('mui-mode') === 'light' ? 'white' : "#1c1b25" : "linear-gradient(266.33deg, #D775FF 1.64%, #8DA8FF 52.14%)"
            }}>

              {!isMiningRunning && !lostConnection && !reconecting && !hasFailed &&
                <>
                  <Stack width={'100%'} flexDirection={'row'} alignItems={'center'}>
                    <Stack style={{ display: 'flex', flexDirection: "row", alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                      <Typography sx={{ fontSize: ['25px', '36px', '45px'], color: '#FFFFFF', fontWeight: '700', maxWidth: '390px', width: "100%", textAlign: "left", lineHeight: ["30px", "36px", "55px"] }}>
                        {!isMiningLoading ? "Click to start mining" : "Node starting ..."}
                      </Typography>
                      <SvgIcon className={isMiningLoading ? "logoMiningLoop" : "logoMining"} component={MiningIcon} inheritViewBox />
                    </Stack>
                  </Stack>

                </>
              }

              {!isMiningRunning && lostConnection && !hasFailed && !reconecting &&
                <Stack width={'100%'} flexDirection={'row'} alignItems={'center'} >
                  <Box display={'flex'} flexDirection={['column', 'column', 'row']} justifyContent={'space-between'} width={'100%'}>
                    <Stack height={['auto', 'auto', '191px']} justifyContent={'center'}>
                      <Stack style={{ zIndex: 2 }}>
                        <Typography sx={{ fontSize: ['36px'], textAlign: 'left', color: "rgba(255, 186, 177, 1)", width: "100%" }}>Connection Lost</Typography>
                        <Typography sx={{ fontSize: ['16px'], textAlign: 'left', color: localStorage.getItem('mui-mode') === 'light' ? "#5F5E60" : "#FFFFFF" }}>You have lost connection with the server. Try again later.</Typography>
                      </Stack>
                    </Stack>
                    <Stack height={['auto', 'auto', '171px']} justifyContent={'center'} paddingTop={'20px'}>
                      <Typography onClick={clearMiningStatus} sx={{ fontSize: ['16px'], textAlign: 'left', color: "#8DA8FF" }}>{!isDismissing ? "Dismiss" : <CircularProgress size={24} color="inherit" />}</Typography>
                    </Stack>
                  </Box>
                </Stack>
              }

              {!isMiningRunning && reconecting &&
                <>
                  <Stack width={'100%'} flexDirection={'row'} alignItems={'center'}>
                    <Stack style={{ display: 'flex', flexDirection: "row", alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                      <Typography sx={{ fontSize: ['25px', '45px'], color: '#FFFFFF', fontWeight: '700', maxWidth: '390px', width: "100%", textAlign: "left", lineHeight: ["30px", "55px"] }}>
                        You’re reconnecting...
                      </Typography>
                      <SvgIcon className={'reconnectingLoop'} component={MiningIcon} inheritViewBox />
                    </Stack>
                  </Stack>

                </>
              }

              {!isMiningRunning && !reconecting && hasFailed &&
                <>
                  <Stack width={'100%'} flexDirection={'row'} alignItems={'center'} >
                    <Box display={'flex'} flexDirection={['column', 'column', 'row']} justifyContent={'space-between'} width={'100%'}>
                      <Stack height={['auto', 'auto', '191px']} justifyContent={'center'}>
                        <Stack style={{ zIndex: 2 }}>
                          <Typography sx={{ fontSize: ['36px'], textAlign: 'left', color: "rgba(255, 186, 177, 1)", width: "100%" }}>Connection Lost</Typography>
                          <Typography sx={{ fontSize: ['16px'], textAlign: 'left', color: localStorage.getItem('mui-mode') === 'light' ? "#5F5E60" : "#FFFFFF" }}>You have lost connection with the server. Try again later.</Typography>
                        </Stack>
                      </Stack>
                      <Stack height={['auto', 'auto', '171px']} justifyContent={'center'} paddingTop={'20px'}>
                        <Typography onClick={clearMiningStatus} sx={{ fontSize: ['16px'], textAlign: 'left', color: "#8DA8FF" }}>{!isDismissing ? "Dismiss" : <CircularProgress size={24} color="inherit" />}</Typography>
                      </Stack>
                    </Box>
                  </Stack>

                </>
              }

              {isMiningRunning &&
                <Stack width={'100%'} flexDirection={'row'} alignItems={'center'} >
                  <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} width={'100%'}>
                    <Stack height={'191px'} justifyContent={'space-between'}>
                      <Stack style={{ zIndex: 2 }}>
                        <Typography sx={{ fontSize: ['12px'], textAlign: 'left', color: "#FFFFFF", width: "100%" }}>Online Miners: {JSON.parse(miningStatus).online}</Typography>
                        {JSON.parse(miningStatus).rate != "0" && <Typography sx={{ fontSize: ['12px'], textAlign: 'left', color: "#FFFFFF" }}>Reward Rate: {JSON.parse(miningStatus).rate} CNTP/ PER second</Typography>}
                      </Stack>
                      <Stack style={{ zIndex: 2 }}>
                        <Typography sx={{ fontSize: ['25px', '45px'], lineHeight: ['25px', '45px'], color: "#FFFFFF", fontWeight: '700', textAlign: "left", width: "100%" }}>{Number(JSON.parse(miningStatus).currentCCNTP) >= 0 ? Number(JSON.parse(miningStatus).currentCCNTP).toFixed(6) : (0).toFixed(6)}</Typography>
                        <Typography sx={{ fontSize: ['12px', '16px'], color: "#FFFFFF", fontWeight: '400', textAlign: "left", width: "100%" }}>CNTP</Typography>
                      </Stack>
                    </Stack>
                    <Stack height={'171px'} justifyContent={'flex-start'} paddingTop={'20px'}>
                      <img src={miningGif} />
                    </Stack>
                  </Box>
                </Stack>
              }
            </StartMiningCard>

            {isMiningRunning && !isLoadingMiner &&
              <Box display={'flex'} flexDirection={'row'} position={'absolute'} top={'16px'} right={'16px'} style={{ cursor: "pointer" }} onClick={stopDePin}>
                <CloseIcon sx={{ fontSize: '20px', color: "white" }} />
                <Typography sx={{ fontSize: ['12px'], textAlign: 'left', color: "#FFFFFF", width: "100%" }}>{"Stop DePIN"}</Typography>
              </Box>
            }

            {isMiningRunning && isLoadingMiner &&
              <Box display={'flex'} flexDirection={'row'} position={'absolute'} top={'16px'} right={'16px'} style={{ cursor: "pointer" }} onClick={stopDePin}>
                <Typography sx={{ fontSize: ['12px'], textAlign: 'left', color: "#FFFFFF", width: "100%" }}>{<CircularProgress size={24} color="inherit" />}</Typography>
              </Box>
            }

          </Box>

        </Stack>
      </Stack>
    </CommunityLivenessWrapper >
  );
};
