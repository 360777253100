import { Stack, TableCell, TableRow } from "@mui/material";
import { CopyToClipboard } from '../../../../../utilities/utilities'
import { MdOutlineContentCopy } from "react-icons/md";
import { Box } from '@mui/material'
import { useTheme } from "styled-components";

type NodesRowsProps = {
    keyId: string,
    ipAddress: string,
    region: string,
    ccntp: string,
    key: number
}

export function NodesRows({ keyId, ipAddress, region, ccntp }: NodesRowsProps) {

    const { colors } = useTheme().ui;

    return (
        <>
            <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
                <TableCell style={{ textAlign: 'left' }} >
                    <Box style={{ display: 'flex', flexDirection: 'row', cursor: "pointer", width: "fit-content" }} onClick={() => CopyToClipboard(keyId)}>
                        {keyId.substring(0, 6) + '...' + keyId.substring(keyId.length - 6)}
                        <MdOutlineContentCopy style={{ marginLeft: '4px' }} />
                    </Box>
                </TableCell>
                <TableCell>
                    <Stack direction="row" alignItems="center" gap=".25rem">
                        <Box
                            mt="2px"
                            width=".5rem"
                            height=".5rem"
                            borderRadius="50%"
                            bgcolor={colors.primary}
                        />
                    </Stack>
                </TableCell>
                <TableCell style={{ textAlign: 'left' }}>
                    {ipAddress}
                </TableCell>
                <TableCell style={{ textAlign: 'left' }}>
                    {region && region != "" && <img src={`https://flagsapi.com/${region?.split(".")[1]}/flat/24.png`} />}
                    
                </TableCell>
                <TableCell style={{ textAlign: 'left' }}>
                    {Number(ccntp)?.toFixed(6)}
                </TableCell>
            </TableRow>
        </>
    )
}