import { useEffect, useRef, useState } from "react";
import {
  styled as materialStyled,
  Box,
  Stack,
  Typography,
  TextField,
  CircularProgress,
} from "@mui/material";
import { Variants, motion } from "framer-motion";
import { PurchaseButtom } from "../../miner/styles/SuperNodeBanner";

import { BridgeProfileDropdown, ProfileDropdown } from "../../bridge/bridgeProfileDropdown";
import DropdownCoin from "./DropdownCoin";
import { useTypedSelector } from "../../../../../store/store";
import DropdownFeaturedWallets from "./DropdownFeaturedWallets";
import { useUserProfile } from "../../../../../API/useUserProfile";
import {
  listeningTokenTransferHook,
  platform,
} from "../../../../../API/platform";
import TransactionResponseModal from "./TransactionResponseModal";
import DropdownTransactionHistory from "./DropdownTransactionHistory";

const SubmitButton = ({
  userProfile,
  isGasFeeUpToDate,
  isCalculatingGas,
  handleTransferTokenButton,
  handleEstimateGasFeeButton,
  isValid,
  amount,
  selectedCoin,
}: any) => {
  const circleDevider =
    localStorage.getItem("mui-mode") === "light" ? "#f6f8ff" : "#303032";

  if (!userProfile?.tokens?.cCNTP?.unlocked) {
    return (
      <PurchaseButtom
        style={{
          width: "100%",
          textAlign: "center",
          margin: "0 auto",
          height: "50px",
          marginTop: "8px",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "600",
          fontSize: "15px",
          color: "#989899",
          padding: "0.65rem",
          borderRadius: "0.75rem",
          cursor: "default",
          backgroundColor: circleDevider,
        }}
        disabled={true}>
        Unlock account before sending assets
      </PurchaseButtom>
    )
  }

  if (isGasFeeUpToDate || !isValid) {
    return (
      <PurchaseButtom
        style={{
          width: "100%",
          textAlign: "center",
          margin: "0 auto",
          height: "50px",
          marginTop: "8px",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "600",
          fontSize: "15px",
          color: isValid ? "" : "#989899",
          padding: "0.65rem",
          borderRadius: "0.75rem",
          cursor: isValid && amount <= userProfile?.tokens[selectedCoin]?.balance ? "pointer" : "default",
          backgroundColor: circleDevider,
        }}
        onClick={handleTransferTokenButton}
        disabled={!isValid || amount > userProfile?.tokens[selectedCoin]?.balance}
      >
        Send
      </PurchaseButtom>
    )
  }

  if (!isCalculatingGas) {
    return (
      <PurchaseButtom
        style={{
          width: "100%",
          textAlign: "center",
          margin: "0 auto",
          height: "50px",
          marginTop: "8px",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "600",
          fontSize: "15px",
          padding: "0.65rem",
          borderRadius: "0.75rem",
          cursor: "pointer",
          backgroundColor: circleDevider,
        }}
        onClick={handleEstimateGasFeeButton}
        disabled={false}
      >
        Estimate Gas Fee
      </PurchaseButtom>
    )
  }

  return (
    <PurchaseButtom
      style={{
        width: "100%",
        textAlign: "center",
        margin: "0 auto",
        height: "50px",
        marginTop: "8px",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "600",
        fontSize: "15px",
        padding: "0.65rem",
        borderRadius: "0.75rem",
        cursor: "default",
      }}
    >
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={1}
      >
        <CircularProgress size={24} color='inherit' /> Processing
      </Box>
    </PurchaseButtom>
  )
};

const TransactionCard = ({
  receiver,
  setReceiver,
}: any) => {
  const { userProfile, setUserProfile } = useUserProfile();

  const [isOpen, setIsOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedCoin, setSelectedCoin] = useState("cCNTP");
  const [amount, setAmount] = useState(0);
  const [conetPlatform, setConetPlatform] = useState<any>("");
  const [workerLoading, setWorkerLoading] = useState(0);
  const [tokenTransferStatus, setTokenTransferStatus] = useState<number>(0);
  const [transactionHash, setTransactionHash] = useState<string>("");
  const [isTransfering, setIsTransfering] = useState<boolean>(false);
  const [isCalculatingGas, setIsCalculatingGas] = useState<boolean>(false);
  const [isGasFeeUpToDate, setIsGasFeeUpToDate] = useState(false);
  const [gasFee, setGasFee] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isCheckingReceiverAddress, setIsCheckingReceiverAddress] = useState(false);
  const [isReceiverAddressValid, setIsReceiverAddressValid] = useState(true);
  const conet_platform = new platform(setConetPlatform, setWorkerLoading);
  const profile = useTypedSelector((state) => state.appState.activeProfile);
  const [transactionHistory, setTransactionHistory] = useState<[]>([])

  const walletAddressChangeTimeout = useRef<any>(null);
  const gasFeeTimeout = useRef<any>(null);

  useEffect(() => {
    setIsValid(false);

    if (amount > 0 && isReceiverAddressValid && receiver !== "") {
      setIsValid(true);
    }

    setIsGasFeeUpToDate(false);
  }, [amount, isReceiverAddressValid, selectedCoin, profile]);

  useEffect(() => {
    if (userProfile) {
      const tempHistory = []

      for (const [key, value] of Object.entries(userProfile?.tokens)) {
        const newHistory = value?.history?.map((item) => {
          return {
            ...item,
            currency: key
          }
        })

        tempHistory.push(...newHistory)
      }

      const orderedTempHistory = tempHistory.sort((a, b) => new Date(b?.time) - new Date(a?.time))

      setTransactionHistory(orderedTempHistory)
    }

    // Number(valor)
  }, [userProfile])

  const handleReceiverAddressChange = async (address: string) => {
    setReceiver(address);
    clearTimeout(walletAddressChangeTimeout.current);
    setIsReceiverAddressValid(false);

    if (address !== "") {
      setIsCheckingReceiverAddress(true);

      walletAddressChangeTimeout.current = setTimeout(() => {
        return conet_platform.isAddress(address).then((result) => {
          if (result) {
            setIsReceiverAddressValid(!!result[0]);
            setIsCheckingReceiverAddress(false);
          }
        });
      }, 3000);
    } else {
      setIsCheckingReceiverAddress(false);
    }
  }

  async function handleTransferTokenButton() {
    setIsTransfering(true);

    if (profile !== null) {
      await conet_platform.transferToken(
        amount,
        profile.keyID,
        selectedCoin,
        receiver
      );
    }
  }

  async function handleEstimateGasFeeButton() {
    setIsCalculatingGas(true);

    if (profile !== null) {
      const data = await conet_platform.estimateGas(
        amount,
        profile.keyID,
        selectedCoin,
        receiver
      );

      if (data.length >= 1) {
        setGasFee(data[1]);
        setIsGasFeeUpToDate(true);

        clearTimeout(gasFeeTimeout.current);
        gasFeeTimeout.current = setTimeout(() => {
          setIsGasFeeUpToDate(false);
        }, 60000);
      }
    }

    setIsCalculatingGas(false);
  }

  const featuredWallets = [""];

  useEffect(() => {
    listeningTokenTransferHook((cmdData: any) => {
      setTokenTransferStatus(cmdData[0]), setTransactionHash(cmdData[1]?.hash);
    });
  });

  return (
    <>
      <Stack
        display={"flex"}
        flexDirection={["column", "row"]}
        gap={"64px"}
        width={"100%"}
      >
        <Box width={"192px"}>
          <Typography variant='h4' sx={{ fontWeight: "500", fontSize: "16px" }}>
            Transaction
          </Typography>
          <Typography
            fontSize={"14px"}
            fontWeight={400}
            color={"#989899"}
            lineHeight={"20px"}
            letterSpacing={"0.25px"}
          >
            Send crypto, fast and safe
          </Typography>
        </Box>
        <Stack width={"100%"}>
          {!isTransfering ? (
            <Box id='transaction-card'>
              <BridgeProfileDropdown selectedProfile={userProfile} setSelectedProfile={setUserProfile}/>

              <Box
                bgcolor={
                  localStorage.getItem("mui-mode") === "light"
                    ? "#F6F8FF"
                    : "#191919"
                }
                borderRadius={"16px"}
                padding={"16px 24px"}
                marginBottom={"24px"}
                border={isSelected ? "1px solid #676768" : ""}
                onInput={() => setIsSelected(true)}
              >
                <Typography
                  color={
                    localStorage.getItem("mui-mode") === "light"
                      ? "#676768"
                      : "#989899"
                  }
                  fontSize={"11px"}
                  fontWeight={500}
                >
                  Send To
                </Typography>
                <DropdownFeaturedWallets
                  currentWalletKeyId={userProfile.keyID}
                  featuredWallets={featuredWallets}
                  setReceiver={setReceiver}
                  receiver={receiver}
                  handleReceiverAddressChange={handleReceiverAddressChange}
                  isCheckingReceiverAddress={isCheckingReceiverAddress}
                  isReceiverAddressValid={isReceiverAddressValid}
                />
              </Box>

              <Box
                bgcolor={
                  localStorage.getItem("mui-mode") === "light"
                    ? "#F6F8FF"
                    : "#191919"
                }
                borderRadius={"16px"}
                padding={"16px 24px"}
                border={isSelected ? "1px solid #676768" : ""}
              >
                <Stack
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                >
                  <Box display={"flex"} gap='4px'>
                    <TextField
                      error={amount > userProfile?.tokens[selectedCoin]?.balance}
                      helperText={amount > userProfile?.tokens[selectedCoin]?.balance ? "Insufficient balance" : ""}
                      value={amount}
                      onChange={(e) => setAmount(parseFloat(e.target.value))}
                      placeholder='Type the amount'
                      variant='standard'
                      type='number'
                      sx={{
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                        {
                          display: "none",
                        },
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                      }}
                    />
                  </Box>

                  <Box display={"flex"} alignItems={"center"}>
                    <DropdownCoin
                      selectedCoin={selectedCoin}
                      setSelectedCoin={setSelectedCoin}
                    />
                  </Box>
                </Stack>
                <Stack
                  display={"flex"}
                  flexDirection={"row-reverse"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  marginTop={'8px'}
                >
                  <Typography display={["none", "block"]} fontSize={'12px'} letterSpacing={'0.5px'}>
                    {userProfile.data
                      ? userProfile.data.nickname
                      : "Anonymous User"}
                  </Typography>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    gap='2px'
                  >
                    <Typography fontSize={'12px'} letterSpacing={'0.5px'}>Your balance: </Typography>
                    <Typography fontSize={'12px'} letterSpacing={'0.5px'}>
                      {userProfile.tokens[selectedCoin].balance} {selectedCoin}
                    </Typography>
                    <Box
                      padding={"2px 8px"}
                      borderRadius={"4px"}
                      bgcolor={"#8DA8FF1A"}
                      display={"flex"}
                      marginLeft={"4px"}
                      alignItems={"center"}
                    >
                      <Typography
                        style={{ cursor: "pointer", fontSize: '12px' }}
                        color='#8DA8FF'
                        textAlign={"center"}
                        onClick={() =>
                          setAmount(userProfile.tokens[selectedCoin].balance)
                        }
                      >
                        MAX
                      </Typography>
                    </Box>
                  </Box>
                </Stack>
              </Box>

              <Stack marginY={'20px'}>
                <Typography marginBottom={"8px"}>
                  Transfer Fees
                </Typography>
                <hr style={{ width: "100%", marginBottom: "40px" }} />
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  marginTop={"-30px"}
                >
                  <Typography
                    color={
                      localStorage.getItem("mui-mode") === "light"
                        ? "#676768"
                        : "#989899"
                    }
                  >
                    Gas Fee
                  </Typography>
                  <Typography
                    color={
                      localStorage.getItem("mui-mode") === "light"
                        ? "#676768"
                        : "#989899"
                    }
                  >
                    $CONET {gasFee}
                  </Typography>
                </Box>

                <SubmitButton
                  userProfile={userProfile}
                  isGasFeeUpToDate={isGasFeeUpToDate}
                  handleTransferTokenButton={handleTransferTokenButton}
                  handleEstimateGasFeeButton={handleEstimateGasFeeButton}
                  isCalculatingGas={isCalculatingGas}
                  isValid={isValid}
                  amount={amount}
                  selectedCoin={selectedCoin}
                />
              </Stack>

              <DropdownTransactionHistory transactionHistory={transactionHistory} />
            </Box>
          ) : (
            <TransactionResponseModal
              setTokenTransferStatus={setTokenTransferStatus}
              transferedAmount={amount}
              tokenTransferStatus={tokenTransferStatus}
              selectedCoin={selectedCoin}
              receiver={receiver}
              setIsTransfering={setIsTransfering}
              transactionHash={transactionHash}
            />
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default TransactionCard;

//
